/**
 * Copyright © 2020 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/diarree-op-reis/"> Diarree op reis </a>
              </li>
              <li>
                <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                  Reizigersdiarree vermijden
                </a>
              </li>
              <li>
                <a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">
                  Reizigersdiarree behandelen
                </a>
              </li>
              <li className="active last">
                <a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div class="page nine columns page-2">
            <h1>Pagina niet gevonden - 404</h1>

            <div class="intro row">
              <div class="twelve columns">
                <p>
                  De pagina die u wilde bezoeken werd niet gevonden.</p>
              </div>
            </div>
            <p>
              Misschien vindt u wat u zoekt op een van de volgende pagina's:</p>
            <ul>
              <li>
                Ga naar de <a href="/">homepagina</a></li>
              <li>
                Bekijk het <a href="/imodium-behandelen-diarree/">productenoverzicht</a></li>
              <li>
                <a href="/feiten-over-diarree/">Oorzaken en behandeling van diarree</a></li>
              <li>
                Of bekijk de <a href="/sitemap/">sitemap</a></li>
            </ul>
          </div>
        </div>
        <script
          type="text/javascript"
          innerHTML="
if(window.top !== window.self || top != self){window.top.location=window.self.location;top.location.replace(location);}
"
        />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
        <link rel="stylesheet" href="/assets/css/screen.css" />


        <script
          type="text/javascript"
          innerHTML="function OptanonWrapper() { }"
        />
        <script
          type="text/javascript"
          innerHTML="
if(window.top !== window.self || top != self){window.top.location=window.self.location;top.location.replace(location);}
"
        />
        <script src="/assets/js/vendor/modernizr-2.6.1.min.js" />

        <Footer></Footer>
      </div>
    );
  }
}

export default Page;
