import React from "react";

class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <div className="inner">
          <div className="row header-top">
            <div className="four offset-by-six columns">
              <nav class="menu-link-nav">
                <ol className="uilist-hor nav-top">
                  <li className="btn first">
                    <a href="/contact/">Contact</a>
                  </li>
                  <li className="btn active parent-active">
                    <a href="/woordenlijst/">Woordenlijst</a>
                  </li>
                  <li className="btn last">
                    <a href="/veelgestelde-vragen-diarree/">FAQ</a>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="two columns">
              <form
                className="search"
                method="post"
                action="https://www.imodium.nl/"
              >
                <div className="hiddenFields">
                  <input
                    type="hidden"
                    name="XID"
                    defaultValue="adf9491d783484301ca4d24f93cc756ab03ababb"
                  />
                  <input type="hidden" name="ACT" defaultValue="45" />
                  <input
                    type="hidden"
                    name="result_page"
                    defaultValue="/zoeken"
                  />
                  <input type="hidden" name="collection" defaultValue="pages" />
                  <input type="hidden" name="search_mode" defaultValue="all" />
                  <input type="hidden" name="site_id" defaultValue="1" />
                </div>
              </form>
            </div>
          </div>
          <div className="row header-bottom">
            <div className="two columns">
              <h1 className="logo">
                <a href="/">Imodium®</a>
              </h1>
            </div>
            <div className="ten columns" id='menu-links'>
              <nav className="top-bar">
                <ul className="nav-info">
                  <li className="btn name">
                    <h1>
                      <a href="#">Menu</a>
                    </h1>
                  </li>
                  <li className="btn toggle-topbar">
                    <a href="#" />
                  </li>
                </ul>
                <section>
                  <ol className="uilist-hor nav-main">
                    <li className="btn first" id="nav-sub-4">
                      <a href="/hoe-werkt-imodium-instant/">
                        Hoe werkt <br />
                        IMODIUM®?{" "}
                      </a>
                    </li>
                    <li id="nav-sub-5">
                      <a href="/imodium-behandelen-diarree/">
                        IMODIUM® <br />
                        Geneesmiddelen
                      </a>
                    </li>
                    <li id="nav-sub-6">
                      <a href="/feiten-over-diarree/">
                        Diarree - oorzaken
                        <br /> en behandeling
                      </a>
                    </li>
                    <li id="nav-sub-8">
                      <a href="/diarree-op-reis/">
                        Diarree
                        <br /> op reis
                      </a>
                    </li>
                    <li className="btn last" id="nav-sub-9">
                      <a href="/diarree-bij-kinderen/">
                        Diarree
                        <br /> bij kinderen
                      </a>
                    </li>
                  </ol>
                </section>
              </nav>
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html:
                    '\n//var item = document.getElementById("nav-sub-10").getElementsByTagName("a");\n//var span = document.createElement("span");\n//span.className = "menu-info";\n//span.innerHTML = " ";\n//item[0].appendChild(span);\n'
                }}
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
